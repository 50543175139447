<script setup>
import { ref, watchEffect } from 'vue';
import { useCoin2CoreStore } from '@/stores/coin2core';
import { onMounted } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
// import ReceiveCoins from '@/components/ReceiveCoins.vue';

const store = useCoin2CoreStore();
const router = useRouter();
const toast = useToast();

const showTimeout = ref(false);
const logoutTime = ref('');

let timeoutId;
let logoutId;

const popupTimer = ref(60 * store.portalTimeout * 1000); // X minutes from the config
let logoutMinutes = 1;
let logoutDuration = 60 * logoutMinutes * 1000; // 1 Minute defaulted on this, as we only have a single portal timer

onMounted(async () => {  
    
});

watchEffect(() => {
    if(store.loginId) {
        //console.info('login was added');
        document.addEventListener("keypress", resetTimer, false);
        document.addEventListener("mousemove", resetTimer, false);
        document.addEventListener("mousedown", resetTimer, false);
        document.addEventListener("touchmove", resetTimer, false);
        startTimer();

    } else {
        //console.info('login was removed');
        document.removeEventListener("keypress", resetTimer, false);
        document.removeEventListener("mousemove", resetTimer, false);
        document.removeEventListener("mousedown", resetTimer, false);
        document.removeEventListener("touchmove", resetTimer, false);
        if(timeoutId) { 
            clearTimeout(timeoutId);
        }
        if(logoutId) { 
            clearTimeout(logoutId);
        }
    }
});
function showPopup() {
    //console.info('in show popup');
    let logoutDate = new Date();
    logoutDate.setMinutes(logoutDate.getMinutes() + logoutMinutes);
    logoutTime.value = logoutDate.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    showTimeout.value = true;
    //console.info('logout timer started');
    logoutId = setTimeout(logout, logoutDuration);
}
function logout() {  
    //console.info('logging out due to timeout');    
    showTimeout.value = false;
    store.loginId = null;
    toast.add({ severity: 'error', summary: 'Logged Out', detail: 'You were logged out due to inactivity', life: 10000});
    router.push( { name: 'login' });  
}

function startTimer() {
    //console.info('popup timer started', popupTimer.value);
    timeoutId = setTimeout(showPopup, popupTimer.value);
}
function resetTimer() {
    //console.info('clearing timer');
    showTimeout.value = false;
    clearTimeout(timeoutId);    
    startTimer();
    
    if(logoutId) {
        clearTimeout(logoutId);            
    }
}


/*function resetTimer() {    
    if(timeoutId) {
        console.info('timeout reset due to activity');
        clearTimeout(timeoutId);
        if(logoutId) {
            showPopup.value = false;
            clearTimeout(logoutId);
        }        
    }
}*/

</script>

<template>
    <div>  
        <Dialog v-model:visible="showTimeout" :header="store.getPortalContentByPageAndContentName('Timeout', 'timeout_modal_title')" :breakpoints="{'2000px': '50vw', '700px': '95vw'}" modal>
            <div class="flex flex-column justify-content-center">        
                <p v-html="store.getPortalContentByPageAndContentName('Timeout', 'timeout_message', null, [ { variable: 'LOGOUT_TIME', value: logoutTime  }])"></p>
            </div>
            <template #footer>
                <div class="flex flex-column justify-content-center align-items-center">
                </div>
            </template>
        </Dialog>      
    </div>  
</template>