<script setup>
import { useCoin2CoreStore } from '../stores/coin2core';

const store = useCoin2CoreStore();

function logo() {
  return 'data:image/png;base64,' + store.portalBranding.logo;
}

</script>

<template>  
<div id="homeLogo">    
    <img :src="logo()" class="logo" />            
</div>
</template>
