import { createRouter, createWebHistory } from 'vue-router'
import { useCoin2CoreStore } from '../stores/coin2core';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/components/Login.vue'),
    },    
    {
      path: '/wallet',
      name: 'wallet',
      component: () => import('@/components/Wallet.vue'),
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/components/Profile.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/components/Register.vue'),
    },
    {
      path: '/passwordchange',
      name: 'passwordChange',
      component: () => import('@/components/PasswordChange.vue'),
    },
    {
      path: '/forgotpassword',
      name: 'forgotPassword',
      component: () => import('@/components/ForgotPassword.vue'),
    },
    {
      path: '/resetpassword',
      name: 'resetPassword',      
      component: () => import('@/components/ResetPassword.vue'),      
    }
  ]
})

router.beforeEach((to) => {  
  const store = useCoin2CoreStore();  

  if(!store.loginId && (to.name !== 'login' && to.name !== 'register' && to.name !== 'forgotPassword' && to.name !== 'resetPassword')) {    
    return { name: 'login'};
  }  
})
export default router
